// Libraries
import { Col, Navbar } from 'react-bootstrap'
import { Link } from 'react-router-dom'

// Component
import Header, { HeaderNav, Menu } from '../Header/Header'

const HeaderNavbar = (props) => {
    return (
        <div>
            <Header topSpace={{ md: true }} type="reverse-scroll">
                <HeaderNav fluid="fluid" theme="dark" expand="lg" className="px-[35px] py-[0px]  md:pl-0 md:pr-[15px] md:py-[20px] sm:pr-0">
                <Col className="col-auto col-sm-6 col-lg-2 mr-auto ps-lg-0 sm:!pl-0">
                    <Link aria-label="header logo" className="flex items-center" to="/">
                    <Navbar.Brand className="inline-block p-0 m-0">
                        <img className="default-logo" width="111" height="36" loading="lazy" src='/assets/img/webp/fidelis-white.webp' data-rjs='/assets/img/webp/fidelis-white.webp' alt='logo' />
                        <img className="alt-logo" width="111" height="36" loading="lazy" src='/assets/img/webp/fidelis-black.webp' data-rjs='/assets/img/webp/fidelis-black.webp' alt='logo' />
                        <img className="mobile-logo" width="111" height="36" loading="lazy" src='/assets/img/webp/fidelis-black.webp' data-rjs='/assets/img/webp/fidelis-black.webp' alt='logo' />
                    </Navbar.Brand>
                    </Link>
                </Col>
                <Navbar.Toggle className="order-last md:ml-[25px] sm:ml-[17px] custom-navbar-toggler">
                    <span className="navbar-toggler-line"></span>
                    <span className="navbar-toggler-line"></span>
                    <span className="navbar-toggler-line"></span>
                    <span className="navbar-toggler-line"></span>
                </Navbar.Toggle>
                <Navbar.Collapse className="col-auto justify-end pr-[15px]">
                    <Menu {...props} />
                </Navbar.Collapse>
                </HeaderNav>
            </Header>
        </div>
    )
}

export default HeaderNavbar