const ReinsurancesData = [
    {
        title: "Swiss Reinsurance Company Ltd.",
        img: "/assets/img/reinsurance/swiss_re_negro.png",
        btnTitle: "Ir al sitio",
        btnLink: "https://www.swissre.com/"
    },
    {
        title: "R+V Versicherung AG",
        img: "/assets/img/reinsurance/R+V_negro.png",
        btnTitle: "Ir al sitio",
        btnLink: "https://www.ruv.de/"
    },
    {
        title: "Hannover Rück SE",
        img: "/assets/img/reinsurance/hannover_re_negro.png",
        btnTitle: "Ir al sitio",
        btnLink: "https://www.hannover-re.com/"
    },
    {
        title: "Everest Reinsurance Company",
        img: "/assets/img/reinsurance/everest_negro.png",
        btnTitle: "Ir al sitio",
        btnLink: "http://www.everestregroup.com/"
    },
    {
        title: "Axxa",
        img: "/assets/img/reinsurance/catlin_axxa_negro.png",
        btnTitle: "Ir al sitio",
        btnLink: "http://xlcatlin.com/"
    },
    {
        title: "Axis Re Public Limited Company",
        img: "/assets/img/reinsurance/axis_negro.png",
        btnTitle: "Ir al sitio",
        btnLink: "http://www.axiscapital.com/en-us"
    },
    {
        title: "Atradius Reinsurance Limited",
        img: "/assets/img/reinsurance/atradius_negro.png",
        btnTitle: "Ir al sitio",
        btnLink: "https://group.atradius.com/"
    }
]

const FooterData = [
    {
        title: "empresa",
        submenu: [
            {
                title: "Acerca de nosotros",
                link: "/nosotros/nuestra-historia"
            },
            {
                title: "Contáctanos",
                link: "/contacto"
            },
            {
                title: "Condiciones generales",
                link: "/informacion-financiera-regulatoria/condiciones-planes"
            },
            {
                title: "Gobierno Corporativo",
                link: "/informacion-financiera-regulatoria/gobierno-corporativo"
            },
        ]
    },
    {
        title: "medios",
        submenu: [
            {

                title: "(+502) 2285-7000",
                link: "tel:+50222857000"
            },
            {
                title: "info@fidelis.gt",
                link: "mailto:info@fidelis.gt"
            },
        ]
    },
]

const IconData = [
    {
        color: "#828282",
        link: "https://api.whatsapp.com/send/?phone=50222857000",
        icon: "fab fa-whatsapp"
    },
    {
        color: "#828282",
        link: "https://www.facebook.com/aseguradorafidelis",
        icon: "fab fa-facebook-f"
    },
    {
        color: "#828282",
        link: "https://gt.linkedin.com/company/aseguradora-fidelis",
        icon: "fab fa-linkedin-in"
    },

]

export { ReinsurancesData, FooterData, IconData }