import React, { memo } from 'react'

// Libraries
import { Row } from 'react-bootstrap'
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { m } from 'framer-motion'
import PropTypes from "prop-types"

// Data
import { InteractiveBannersData02 } from './InteractiveBannersData'

const InteractiveBanners02 = (props) => {
    const swiperRef = React.useRef(null)
    return (
        <Row className={`interactivebanners-style-02 md:mr-[-15px] sm:contents ${props.className}`}>
            <Swiper className="white-move" modules={[Autoplay, Pagination, Navigation]} {...props.carousalOption} ref={swiperRef} >
                {
                    props.data.map((item, i) => {
                        return (
                            <SwiperSlide key={i} className="my-swiper">
                                <m.div className="col text-center interactive-banners-style-11">
                                    <figure className="rounded-[6px] overflow-hidden relative">
                                        <img width="" height="" src={item.img} alt="" />
                                        <figcaption>
                                        <div className="opacity-70 top-0 left-0 absolute w-full h-full bg-darkgray"></div>
                                        <div className="flex flex-col items-center justify-center h-full">
                                            <a aria-label="link" href={item.btnLink} rel="noreferrer" target="_blank" className="bg-[#c4d600] z-[1] p-3 text-sm leading-[20px] rounded-full w-[95px] h-[95px] font-serif uppercase text-white flex flex-col justify-center">{item.btnTitle}</a>
                                        </div>
                                        </figcaption>
                                    </figure>
                                </m.div>
                            </SwiperSlide>
                        )
                    })
                }
            </Swiper>
        </Row>
    )
}

InteractiveBanners02.defaultProps = {
    data: InteractiveBannersData02,
    animationDelay: 0.2
}

InteractiveBanners02.propTypes = {
    className: PropTypes.string,
    animationDelay: PropTypes.number,
    animation: PropTypes.object,
    data: PropTypes.arrayOf(
        PropTypes.exact({
            subtitle: PropTypes.string,
            title: PropTypes.string,
            img: PropTypes.string,
            btnTitle: PropTypes.string,
            btnLink: PropTypes.string,
        })
    )
}

export default memo(InteractiveBanners02)