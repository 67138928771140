import React, { memo } from 'react'

// Libraries
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { PropTypes } from "prop-types";

// Components
import SocialIcons from '../SocialIcon/SocialIcons'
import FooterMenu, { Footer } from './Footer'
import InteractiveBanners02 from '../InteractiveBanners/InteractiveBanners02';

// Data
import { ReinsurancesData, FooterData, IconData } from '../../Data/FooterData';

// Functions
import { fadeIn } from '../../Functions/GlobalAnimations';

const FooterStyle12 = (props) => {
    return (
        <Footer theme={props.theme} className={`${props.className ? ` ${props.className}` : ""}`}>
            <div className="footer-menu xs:py-[10px] bg-white">
                <section className="py-20 overflow-hidden">
                    <Container>
                        <Row>
                            <Col className="relative">
                                <InteractiveBanners02
                                    carousalOption={{
                                    slidesPerView: 1,
                                    spaceBetween: 30,
                                    loop: true,
                                    keybord: { enable: true, onlyInViewport: true },
                                    autoplay: { delay: 3500, disableOnInteraction: false },
                                    breakpoints: { 1200: { slidesPerView: 4 }, 992: { slidesPerView: 3 }, 768: { slidesPerView: 2 } }
                                    }}
                                    data={ReinsurancesData}
                                    className=""
                                    animation={fadeIn}
                                />
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>
            <div className="pt-[6%] lg:pt-[8%] footer-menu xs:py-[50px]">
                <Container>
                    <Row className="justify-between md:text-start gap-y-[60px]">
                        <Col lg={{ span: 3, order: 0 }} md={6} sm={{ span: 6, order: 1 }}>
                            <Link aria-label="link" to="/" className="mb-[30px] inline-block xs:mb-[20px]">
                                <img className="default-logo" width="111" height="36" loading="lazy" src='/assets/img/webp/fidelis-white.webp' data-rjs='/assets/img/webp/logo-black@2x.webp' alt='logo' />
                            </Link>
                            <p className="subtitle font-serif font-light w-[95%] lg:w-full md:w-[70%] sm:w-full text-[16px] leading-[33px] -tracking-[0.5px] xs:w-[90%]">
                                <a aria-label="footer menu link" href="https://ul.waze.com/ul?preview_venue_id=176619666.1766131124.2518683&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location">
                                    12 Calle 2-25, Centro Corporativo AVIA, Nivel 2, Torre 3, Oficinas 201 y 218, Zona 10.
                                </a>
                            </p>
                        </Col>
                        <FooterMenu className="justify-between" data={FooterData} lg={{ span: "auto", order: 0 }} sm={{ span: 6, order: 3 }} titleClass="uppercase traking-[2px]" />
                        <Col lg={{ span: "auto", order: 0 }} md={6} sm={{ span: 6, order: 2 }} className="instagram-col justify-end">
                            <span className="font-serif tracking-[2px] uppercase portrait:text-center landscape:text-start font-medium block text-themecolor mb-[30px] md:my-[15px] portrait:md:text-start">Síguenos</span>
                            <SocialIcons theme="social-icon-style-01" className="portrait:justify-center landscape:justify-start flex portrait:md:justify-start" size="xs" iconColor={props.theme === "dark" ? "light" : "dark"} data={IconData} />
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="py-[3%] xs:pt-0 xs:p-0 footer-details">
                <Container>
                    <Row>
                        <Col sm={{ span: 12, order: 2 }} md={{ span: 12, order: 1 }} xs={{ order: 2 }} className="text-start flex sm:justify-center md:text-end md:mb-4 xs:justify-start">
                            <p>&copy; {new Date().getFullYear()}, Derechos reservados Aseguradora Fidelis S.A. </p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Footer>
    )
}

FooterStyle12.defaultProps = {
    data: FooterData,
    logo: "/assets/img/webp/logo.webp"
}

FooterStyle12.propTypes = {
    className: PropTypes.string,
    logo: PropTypes.string
}

export default memo(FooterStyle12)