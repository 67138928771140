/**
 * Deriva una clave AES-GCM desde una passphrase usando PBKDF2
 * @param {string} passphrase
 * @param {Uint8Array} salt (16 bytes aleatorios)
 * @returns {Promise<CryptoKey>} 
 */
async function deriveKey(passphrase, salt) {
  // Codificamos la passphrase a binario (UTF-8)
  const passphraseBytes = new TextEncoder().encode(passphrase);

  // Creamos un objeto 'keyMaterial' a partir de la passphrase
  const keyMaterial = await window.crypto.subtle.importKey(
    "raw",
    passphraseBytes,
    { name: "PBKDF2" },
    false,
    ["deriveBits", "deriveKey"]
  );

  // Derivamos una clave AES-GCM de 256 bits
  return window.crypto.subtle.deriveKey(
    {
      name: "PBKDF2",
      salt: salt,
      iterations: 100000, // número alto para mayor seguridad
      hash: "SHA-256",
    },
    keyMaterial,
    { name: "AES-GCM", length: 256 },
    false,
    ["encrypt", "decrypt"]
  );
}

/**
 * Cifra datos (string) usando AES-GCM con una passphrase
 * Retorna un objeto en base64 que incluye salt, iv y ciphertext.
 * @param {string} data
 * @param {string} passphrase
 * @returns {Promise<string>}
 */
async function encryptData(data, passphrase) {
  // 1. Generamos un salt aleatorio (16 bytes)
  const salt = window.crypto.getRandomValues(new Uint8Array(16));

  // 2. Derivamos la clave AES-GCM
  const key = await deriveKey(passphrase, salt);

  // 3. Generamos IV (nonce) de 12 bytes (recomendado en GCM)
  const iv = window.crypto.getRandomValues(new Uint8Array(12));

  // 4. Ciframos
  const encodedData = new TextEncoder().encode(data);
  const encryptedBuffer = await window.crypto.subtle.encrypt(
    {
      name: "AES-GCM",
      iv: iv,
    },
    key,
    encodedData
  );

  // encryptedBuffer es un ArrayBuffer que contiene ciphertext + tag
  const encryptedBytes = new Uint8Array(encryptedBuffer);

  // Concatenamos: [salt(16) | iv(12) | ciphertext+tag]
  const fullMessage = new Uint8Array(
    salt.byteLength + iv.byteLength + encryptedBytes.byteLength
  );
  fullMessage.set(salt, 0);
  fullMessage.set(iv, salt.byteLength);
  fullMessage.set(encryptedBytes, salt.byteLength + iv.byteLength);


  // Convertimos a Base64 para transportar/almacenar
  const base64Message = btoa(String.fromCharCode(...fullMessage));
  return base64Message;
}

  export const encrypt = async data => await encryptData(data,process.env.REACT_APP_SECRET)