import React, { Suspense, useEffect, useState, lazy, useMemo } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import retina from "retinajs";
import { AnimatePresence } from "framer-motion";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Context
import GlobalContext from "./Context/Context";

import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';

// Components
import ScrollToTopButton from "./Components/ScrollToTop";
import HeaderNavbar from "./Components/Navbar/HeaderNavbar";
import FooterStyle12 from "./Components/Footers/FooterStyle12";
import CustomModal from "./Components/CustomModal";
import { getNews } from "./Services/NewsService";
import { encrypt } from "./Functions/Crypto";

// Pages
const HomePage = lazy(() => import("./Pages/Home/Home"));
const AboutUsPage = lazy(() => import("./Pages/About/AboutUs"));
const ProductsPage = lazy(() => import("./Pages/Products/Products"));
const DigitalServicesPage = lazy(() => import("./Pages/Products/DigitalServices"));
const CorporateGovernancePage = lazy(() => import("./Pages/Finance/CorporateGovernance"));
const OurAgentsPage = lazy(() => import("./Pages/Finance/OurAgents"));
const OurReinsurersPage = lazy(() => import("./Pages/Finance/OurReinsurers"));
const ConditionsPlansPage = lazy(() => import("./Pages/Finance/ConditionsPlans"));
const FinancialInformationPage = lazy(() => import("./Pages/Finance/FinancialInformation"));
const FinancialInformationPageV2 = lazy(() => import("./Pages/Finance/FinancialInformationV2"));
const AdministrativeCouncilPage = lazy(() => import("./Pages/Finance/AdministrativeCouncil"));
const ContactUsPage = lazy(() => import("./Pages/Contact/ContactUs"));

function App() {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customModal, setCustomModal] = useState({ el: null, isOpen: false });
  const location = useLocation();

  const [showNew, setShowNew] = useState(false);
  const [newSrc, setNewSrc] = useState('');

  // Memoize context value to prevent re-renders
  const contextValue = useMemo(() => ({
    headerHeight,
    setHeaderHeight,
    footerHeight,
    setFooterHeight,
    isModalOpen,
    setIsModalOpen,
    customModal,
    setCustomModal,
  }), [headerHeight, footerHeight, isModalOpen, customModal]);

  // RetinaJS
  useEffect(() => {
    const handleLoad = () => retina(document.querySelectorAll("img"));
    window.addEventListener("load", handleLoad);
    return () => window.removeEventListener("load", handleLoad);
  }, []);

  // Get news
  useEffect(() => {
    const now = new Date();

    const year    = now.getFullYear();
    const month   = String(now.getMonth() + 1).padStart(2, '0');
    const day     = String(now.getDate()).padStart(2, '0');
    const hours   = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    
    const guatemalaDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    console.log(guatemalaDate)
    encrypt(guatemalaDate).then(e=>
      getNews(e)
      .then((res) => {
        if(res && res.status === 1 && res.data.length > 0) {
          const uuid = res.data[0].uuid;
          setNewSrc(`${process.env.REACT_APP_SERVER_URL}api/v1/news/${uuid}`);
          setShowNew(true);
        }
      })
      .catch((err) => console.log(err))
    );
  }, []);

  useEffect(() => {
    import("./Functions/Utilities").then(({ SetHeaderMenuPos, setDocumentFullHeight }) => {
      SetHeaderMenuPos();
      setDocumentFullHeight();
    });
  }, [location]);

  useEffect(() => {
    document.body.classList.toggle("overflow-hidden", isModalOpen);
  }, [isModalOpen]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    setFooterHeight(0);
    setCustomModal({ el: null, isOpen: false });
  }, [location]);

  return (
    <GlobalContext.Provider value={contextValue}>
      <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
        <div className="App" style={{ "--header-height": `${headerHeight}px` }}>
          <HeaderNavbar style={{ "--base-color": "#c4d600" }}/>
          <main style={{ marginTop: headerHeight, marginBottom: footerHeight }}>
            <ScrollToTopButton />
            <AnimatePresence mode="wait">
              <Suspense fallback={<></>}>
                <Routes>
                  <Route path="/" element={<HomePage style={{ "--base-color": "#c4d600" }}/>} />
                  <Route path="nosotros">
                    <Route path="nuestra-historia" element={<AboutUsPage style={{ "--base-color": "#c4d600" }} />} />
                    <Route path="nuestro-respaldo" element={<></>} />
                  </Route>
                  <Route path="productos-servicios-digitales">
                    <Route path="productos" element={<ProductsPage style={{ "--base-color": "#c4d600" }} />} />
                    <Route path="servicios-digitales" element={<DigitalServicesPage style={{ "--base-color": "#c4d600" }} />} />
                  </Route>
                  <Route path="informacion-financiera-regulatoria">
                    <Route path="informacion-financiera" element={<FinancialInformationPage style={{ "--base-color": "#c4d600" }} />} />
                    <Route path="informacion-financiera-v2" element={<FinancialInformationPageV2 style={{ "--base-color": "#c4d600" }} />} />
                    <Route path="gobierno-corporativo" element={<CorporateGovernancePage style={{ "--base-color": "#c4d600" }} />} />
                    <Route path="condiciones-planes" element={<ConditionsPlansPage style={{ "--base-color": "#c4d600" }} />} />
                    <Route path="consejo-administrativo" element={<AdministrativeCouncilPage style={{ "--base-color": "#c4d600" }} />} />
                    <Route path="nuestros-agentes" element={<OurAgentsPage style={{ "--base-color": "#c4d600" }} />} />
                    <Route path="nuestros-reaseguradores" element={<OurReinsurersPage style={{ "--base-color": "#c4d600" }} />} />
                  </Route>
                  <Route path="contacto" element={<ContactUsPage style={{ "--base-color": "#c4d600" }} />} />
                  {/* Ruta para manejar páginas no encontradas */}
                  <Route path="*" element={<Navigate to="/" />} />
                </Routes>
                <FooterStyle12 className="text-[#828282] bg-darkgray" theme="dark"/>
                <CustomModal.Wrapper isOpen={showNew}>
                  <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '90vw',    // 90% del ancho de la ventana
                        height: '90vh',   // 90% de la altura de la ventana
                        overflow: 'auto', // barras de scroll en caso de desborde
                      }}
                    >
                      <img
                        src={newSrc}
                        alt="Imagen"
                        style={{
                          maxWidth: '100%',
                          maxHeight: '100%',
                          width: 'auto',
                          height: 'auto',
                          display: 'block',
                        }}
                      />
                    </div>
                </CustomModal.Wrapper>
              </Suspense>
            </AnimatePresence>
            <ToastContainer />
          </main>
        </div>
      </GoogleReCaptchaProvider>
    </GlobalContext.Provider>
  );
}

export default App;
