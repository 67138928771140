import { encrypt } from "../../Functions/Crypto";

function sortedJSONStringify(obj) {
  if (obj === null || typeof obj !== "object") {
    return JSON.stringify(obj);
  }
  
  // Ordenar las claves alfabéticamente
  const sortedKeys = Object.keys(obj).sort();
  
  // Construir un nuevo objeto con las claves ordenadas
  const sortedObj = {};
  for (const key of sortedKeys) {
    sortedObj[key] = obj[key];
  }
  
  
  // Serializar el objeto ordenado
  return JSON.stringify(sortedObj);
}

async function generateSignature(secretKey, method, path, timestamp, bodyHash = "") {
  // 1. Crear la cadena a firmar
  const stringToSign = `${method.toUpperCase()}${path}${timestamp}${bodyHash}`;

  // 2. Convertir la clave secreta y la cadena a firmar en formato Uint8Array
  const encoder = new TextEncoder();
  const keyData = encoder.encode(secretKey); // Secret key
  const data = encoder.encode(stringToSign); // String to sign

  // 3. Importar la clave secreta como CryptoKey
  const cryptoKey = await crypto.subtle.importKey(
    "raw",                  // Formato de la clave (raw key material)
    keyData,                // Clave en formato Uint8Array
    { name: "HMAC", hash: { name: "SHA-256" } }, // Configuración HMAC con SHA-256
    false,                  // La clave no es exportable
    ["sign"]                // Permitir solo firmas
  );

  // 4. Generar la firma HMAC
  const signatureBuffer = await crypto.subtle.sign("HMAC", cryptoKey, data);

  // 5. Convertir la firma (ArrayBuffer) a una representación hexadecimal
  const signatureArray = Array.from(new Uint8Array(signatureBuffer));
  const signatureHex = signatureArray.map(b => b.toString(16).padStart(2, "0")).join("");

  return signatureHex;
}

async function generateBodyHash(body) {
  // Convertir el body a string JSON y luego a Uint8Array
  const encoder = new TextEncoder();
  const bodyData = encoder.encode(sortedJSONStringify(body));

  // Generar el hash SHA-256 usando la Web Crypto API
  const hashBuffer = await crypto.subtle.digest("SHA-256", bodyData);

  // Convertir el hash (ArrayBuffer) a una representación hexadecimal
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, "0")).join("");

  return hashHex;
}


export async function consumeService({ url, method, body = null }) {

  const apiKey = process.env.REACT_APP_PWF_API_KEY;
  const apiSecret = process.env.REACT_APP_PWF_API_SECRET;
  const timestamp = Math.floor(Date.now() / 1000).toString(); // seg. UNIX
  const baseUrl = `${process.env.REACT_APP_SERVER_URL}${url}`
  const bodyHash = body ? await generateBodyHash(body): ''
  const path = new URL(baseUrl)
  const signature = await generateSignature(apiSecret, method, path.pathname, timestamp, bodyHash)

  const encodeApiKey = await encrypt(apiKey)
  const encodeSignature = await encrypt(signature)


  const res = await fetch(baseUrl, {
    method,
    body,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      "X-API-Key": encodeApiKey,
      "X-Signature": encodeSignature,
      "X-Timestamp": timestamp
    }
  })
  const content = await res.json()
  return content
}
