const HeaderData = [
    {
        title: 'Inicio',
        link: '/',
        dropdown: [
            {
                title: '¿Quiénes Somos?',
                to: 'quienes-somos-1',
                href: '/',
            },
            {
                title: '¿Por qué confiar en nosotros?',
                to: 'confiar-nosotros',
                href: '/',
            },
            {
                title: 'Nuestros Números',
                to: 'nuestros-numeros',
                href: '/',
            },
            {
                title: 'Productos',
                to: 'productos',
                href: '/',
            },
        ],
    },
    {
        title: 'Nosotros',
        dropdown: [
            {
                title: '¿Quiénes Somos?',
                link: '/nosotros/nuestra-historia',
                to:'quienes-somos-2',
                dropdown: [

                    {
                        title: 'Nuestra Historia',
                        to: 'nuestra-historia',
                        href: '/nosotros/nuestra-historia',
                    },
                    {
                        title: 'Misión Empresarial',
                        to: 'mision-vision-valores',
                        href: '/nosotros/nuestra-historia?tab=0',
                    },
                    {
                        title: 'Promesa de Marca',
                        to: 'mision-vision-valores',
                        href: '/nosotros/nuestra-historia?tab=1',
                    },
                    {
                        title: 'Nuestros Valores',
                        to: 'mision-vision-valores',
                        href: '/nosotros/nuestra-historia?tab=2',
                    },
                ]
            },
            // {
            //     title: "Nuestro Respaldo",
            //     link: '/nosotros/nuestro-respaldo'
            // }
        ]
    },
    {
        title: 'Productos y Servicios Digitales',
        dropdown: [
            {
                title: 'Productos',
                link: '/productos-servicios-digitales/productos',
                dropdown: [
                    {
                        title: 'Tradicionales',
                        to: 'tradicionales',
                        href: '/productos-servicios-digitales/productos'
                    },
                    {
                        title: 'Crédito Comercial',
                        to: 'credito-comercial',
                        href: '/productos-servicios-digitales/productos'
                    },
                    {
                        title: 'Crédito Vivienda',
                        to: 'credito-vivienda',
                        href: '/productos-servicios-digitales/productos'
                    },
                    {
                        title: 'Servicios Profesionales',
                        to: 'servicios-profesionales',
                        href: '/productos-servicios-digitales/productos'
                    }
                ]
            },
            {
                title: 'Servicios Digitales',
                link: '/productos-servicios-digitales/servicios-digitales',
                dropdown: [
                    {
                        title: 'Portal Agentes',
                        to: 'portal-agentes',
                        href: '/productos-servicios-digitales/servicios-digitales'
                    },
                    {
                        title: 'Pago de Facturas',
                        to: 'pago-facturas',
                        href: '/productos-servicios-digitales/servicios-digitales'
                    },
                    {
                        title: 'Autenticación de Pólizas',
                        to: 'autenticacion-polizas',
                        href: '/productos-servicios-digitales/servicios-digitales'
                    },
                    {
                        title: 'Fidelis Digital Servicios Profesionales',
                        to: 'fidelis-digital',
                        href: '/productos-servicios-digitales/servicios-digitales'
                    }
                ]
            }
        ]
    },
    {
        title: 'Autenticación de Pólizas',
        link: 'https://servicios.aseguradorafidelis.com/solicitudes_web/validador',
    },
    {
        title: 'Información Financiera Regulatoria',
        dropdown: [
            {
                title: 'Información Financiera',
                link: '/informacion-financiera-regulatoria/informacion-financiera',
                dropdown: [
                    {
                        title: 'Balance General',
                        to: 'informacion-financiera',
                        href: '/informacion-financiera-regulatoria/informacion-financiera?type=BG'
                    },
                    {
                        title: 'Estados de Resultados',
                        to: 'informacion-financiera',
                        href: '/informacion-financiera-regulatoria/informacion-financiera?type=ER'
                    },
                    {
                        title: 'Activos Aceptables',
                        to: 'informacion-financiera',
                        href: '/informacion-financiera-regulatoria/informacion-financiera?type=PP'
                    },
                    {
                        title: 'Dictámenes Auditoría',
                        to: 'informacion-financiera',
                        href: '/informacion-financiera-regulatoria/informacion-financiera?type=DAOIF'
                    },
                    {
                        title: 'Estados de Flujos de Efectivo',
                        to: 'informacion-financiera',
                        href: '/informacion-financiera-regulatoria/informacion-financiera?type=DAEMCC'
                    },
                    {
                        title: 'Estados de Movimientos del Capital Contable',
                        to: 'informacion-financiera',
                        href: '/informacion-financiera-regulatoria/informacion-financiera?type=DAEFE'
                    },
                    {
                        title: 'Indicadores Financieros',
                        to: 'informacion-financiera',
                        href: '/informacion-financiera-regulatoria/informacion-financiera?type=IF'
                    },
                    {
                        title: 'Estados de Resultados Técnicos Trimestrales',
                        to: 'informacion-financiera',
                        href: '/informacion-financiera-regulatoria/informacion-financiera?type=ERTT'
                    },
                    {
                        title: 'Informes de Calificación de Riesgos',
                        to: 'informacion-financiera',
                        href: '/informacion-financiera-regulatoria/informacion-financiera?tab=ICR'
                    },
                ]
            },
            {
                title: 'Gobierno Corporativo',
                link: '/informacion-financiera-regulatoria/gobierno-corporativo',
                dropdown: [
                    {
                        title: 'Estructura Organizacional',
                        to: 'gobierno-corporativo',
                        href: '/informacion-financiera-regulatoria/gobierno-corporativo?tab=0'
                    },
                    {
                        title: 'Manual de Gobierno Corporativo',
                        to: 'gobierno-corporativo',
                        href: '/informacion-financiera-regulatoria/gobierno-corporativo?tab=1'
                    },
                    {
                        title: 'Informe de Gobierno',
                        to: 'gobierno-corporativo',
                        href: '/informacion-financiera-regulatoria/gobierno-corporativo?tab=2'
                    },
                ]
            },
            {
                title: 'Condiciones y Planes',
                link: '/informacion-financiera-regulatoria/condiciones-planes',
                dropdown: [
                    {
                        title: 'Condiciones General',
                        to: 'condiciones-planes',
                        href: '/informacion-financiera-regulatoria/condiciones-planes?tab=0'
                    },
                    {
                        title: 'Planes de Seguro Vigentes',
                        to: 'condiciones-planes',
                        href: '/informacion-financiera-regulatoria/condiciones-planes?tab=1'
                    },
                ]
            },
            {
                title: 'Consejo de Administración y Alta Gerencia',
                to: 'consejo-administrativo',
                href: '/informacion-financiera-regulatoria/consejo-administrativo?tab=0',
            },
            {
                title: 'Nuestros Agentes',
                to: 'lista-agentes',
                href: '/informacion-financiera-regulatoria/nuestros-agentes?tab=0',
            },
            {
                title: 'Nuestros Reaseguradores',
                to: 'lista-agentes',
                href: '/informacion-financiera-regulatoria/nuestros-reaseguradores?tab=0',
            }
        ]
    }
]

export default HeaderData